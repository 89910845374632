<template>
  <r-page>
    <template #page-header>
      <h2 class="page-title">Product Package</h2>
      <r-menu-tab :level="2" :showed-menus="3"></r-menu-tab>
    </template>
    <template #action-bar>
      <div class="level">
        <div class="level-left">
          <b-button
            type="is-primary"
            icon-left="plus"
            label="Add Package"
            tag="router-link"
            :to="`/add-package/${$route.params.subscriptionType}`"
          ></b-button>
        </div>
        <div class="level-right">
          <div class="search-lp">
            <b-input
              type="is-light"
              placeholder="Search"
              icon-right="magnify"
              icon-right-clickable
              @icon-right-click="searchIconClick(search)"
              @keydown.native.enter="searchIconClick(search)"
              v-model="search"
            ></b-input>
          </div>
        </div>
      </div>
    </template>
    <template #page-content>
      <div
        id="table-request-employment"
        class="table-timeoff-type table-request-personal"
      >
        <b-table
          :data="data ? data.data : []"
          :per-page="perPage"
          :loading="isVoucherLoading"
          :total="total ? total : 0"
          @page-change="onPageChange"
          ref:table
          hoverable
          paginated
          backend-sorting
          backend-pagination
          :default-sort-direction="defaultSortOrder"
          :default-sort="[sortField, sortOrder]"
          class="table-user-management"
          :sticky-header="stickyHeaders"
          aria-next-label="Next page"
          aria-previous-label="Previous page"
          aria-page-label="Page"
          aria-current-label="Current page"
        >
          <template>
            <b-table-column v-slot="props" field="id" label="No." width="5%">
              <span>
                {{ props.index + 1 }}
              </span>
            </b-table-column>
            <b-table-column
              v-slot="props"
              field="name"
              label="Name Package"
              width="20%"
            >
              <span>
                {{ props.row.name }}
              </span>
            </b-table-column>
            <b-table-column
              v-slot="props"
              field="price"
              label="Price"
              width="15%"
            >
              <span>Rp. {{ props.row.price }}</span>
            </b-table-column>
            <b-table-column
              v-slot="props"
              field="maxuser"
              label="Max Users"
              width="11%"
            >
              <span>
                {{ props.row.maxUser }}
              </span>
            </b-table-column>
            <b-table-column
              v-slot="props"
              field="discount"
              label="Discount"
              width="10%"
            >
              <span>
                {{
                  props.row.currentDiscount == null
                    ? '-'
                    : props.row.currentDiscount.amount + '%'
                }}
              </span>
            </b-table-column>
            <b-table-column
              v-slot="props"
              field="description1"
              label="Description 1"
              width="20%"
            >
              <span>
                {{ props.row.description1 }}
              </span>
            </b-table-column>
            <b-table-column
              v-slot="props"
              field="description2"
              label="Description 2"
              width="20%"
            >
              <span>
                {{ props.row.description2 }}
              </span>
            </b-table-column>
            <b-table-column
              v-slot="props"
              field="label"
              label="Label Tag"
              width="30%"
            >
              <span>
                {{ props.row.tags[0] ? props.row.tags[0].name : '-' }}
              </span>
            </b-table-column>
            <b-table-column v-slot="props" label="Highlight" width="15%">
              <span
                :class="
                  props && props.row.isHighlighted
                    ? 'active-class'
                    : 'inactive-class'
                "
              >
                {{ props.row.isHighlighted ? 'Active' : 'Disable' }}
                <!-- {{ props.row.isActive }} -->
              </span>
            </b-table-column>
            <b-table-column
              field="action"
              label="Action"
              v-slot="props"
              width="5%"
            >
              <b-icon
                icon="pencil"
                custom-size="mdi-18px"
                class="click"
                @click.native="openEditPage(props.row.id)"
              ></b-icon>
              <b-icon
                icon="delete"
                class="pointer-click"
                custom-size="mdi-18px"
                @click.native="openDelete(props.row.id)"
              ></b-icon>
            </b-table-column>
          </template>
          <template slot="empty">
            <section class="section">
              <div class="content has-text-grey has-text-centered">
                No data yet, please contact us if you have any problems
                (hello@getrise.id)
              </div>
            </section>
          </template>
        </b-table>
      </div>
      <WarningModal
        :isModalOpen="isDeleteOpen"
        :title="'Are you sure?'"
        :subtitle="'are you sure you want to delete this voucher?'"
        @close="closeModalDelete"
        @run="deletePackages(selected)"
      ></WarningModal>
    </template>
  </r-page>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { showToast } from '@/services/util'
import moment from 'moment-timezone'
import WarningModal from '../../components/WarningModal.vue'
export default {
  components: { WarningModal },
  data() {
    return {
      data: null,
      isVoucherLoading: false,
      subscriptionType: this.$route.params.subscriptionType,
      perPage: 10,
      page: 0,
      lastPage: 0,
      total: 0,
      isPaginated: true,
      stickyHeaders: true,
      defaultSortOrder: 'asc',
      sortField: 'id',
      sortOrder: 'desc',
      search: '',
      selected: 0,

      isDeleteOpen: false,
    }
  },

  async mounted() {
    this.setPackages([])
    await this.loadPackagesData(this.subscriptionType)
  },

  computed: {
    ...mapGetters({
      getPackages: 'packages/getPackagesData',
    }),
    routeParam() {
      return this.$route.params.subscriptionType
    },
  },

  watch: {
    routeParam: {
      async handler() {
        this.setPackages([])
        await this.loadPackagesData(this.$route.params.subscriptionType)
      },
      immediate: true,
    },
  },

  methods: {
    ...mapActions({
      actionLoadPackagesData: 'packages/fetchPackagesData',
      purgePackages: 'packages/deletePackages',
    }),

    ...mapMutations({
      setPackages: 'packages/setPackagesData',
    }),

    // async onSort(field, order) {
    //   this.sortField = field
    //   this.sortOrder = order
    //   this.setVoucher([])
    //   await this.loadPackagesData()
    // },

    onPageChange(page) {
      this.page = page
      this.loadPackagesData()
    },

    async loadPackagesData(type) {
      try {
        const params = {
          page: this.page,
          perPage: this.perPage,
          subscriptionType: type,
        }
        const response = await this.actionLoadPackagesData(params)
        if (response && response.status === 200) {
          this.data = response.data
        }
        this.lastPage = response.data.meta.last_page
        this.total = response.data.meta.total
        this.from = response.data.meta.from
      } catch (e) {
        showToast(e.response.data.message, 'is-danger', 'is-top')
      }
    },

    openEditPage(id) {
      this.$router.push(
        `/edit-package/${this.$route.params.subscriptionType}/${id}`
      )
    },

    formatDate(str) {
      if (str) {
        return moment(str).format('DD MMMM YYYY')
      }
    },

    openDelete(id) {
      this.selected = id
      this.isDeleteOpen = true
    },

    closeModalDelete() {
      this.selected = 0
      this.isDeleteOpen = false
    },

    async deletePackages(id) {
      try {
        await this.purgePackages(id)
        this.closeModalDelete()
        this.$swal({
          icon: 'success',
          titleText: 'Package deleted!',
          confirmButtonText: 'Done',
          customClass: {
            confirmButton: 'button is-success',
          },
        })
        this.setPackages([])
        await this.loadPackagesData()
      } catch (e) {
        this.closeModalDelete()
        this.$swal({
          icon: 'error',
          titleText: 'Failed to delete package!',
          text: e.response.message,
          confirmButtonText: 'Ok',
          customClass: {
            confirmButton: 'button is-primary',
          },
        })
      }
    },
  },
}
</script>
